@font-face
{
  font-family: 'Futura';
  src: url('Futura.ttf') format('truetype');
}

@font-face
{
  font-family: 'Futura-Bold';
  src: url('futura-medium-bt.ttf') format('truetype');
}

@font-face
{
  font-family: 'Futura-Medium';
  src: url('Futura-Medium.ttf') format('truetype');
}

@font-face
{
  font-family: 'Futura-Medium-Italic';
  src: url('Futura-Medium-Italic.ttf') format('truetype');
}