@import './assets/fonts/fonts.css';
@import './assets/fonts/fonts-ios.css';

* {
  outline: none;
}

/* body {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important; 
}
body::-webkit-scrollbar {
	display: none !important;
} */

html,
body,
#root {
  font-family: "Futura-Medium",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  height: 100%;
  line-height: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-28 {
  font-size: 28px;
}

.font-30 {
  font-size: 30px;
}

.font-36 {
  font-size: 36px;
}

.font-48 {
  font-size: 48px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-16 {
	margin-left: 16px;
}

.ml-18 {
	margin-left: 18px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-24 {
	margin-left: 24px;
}

.mr-16 {
  margin-right: 16px;
}

.mt-6 {
	margin-top: 6px;
}

.mt-8 {
	margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-18 {
	margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-44 {
  margin-top: 44px;
}

.mt-48 {
  margin-top: 48px;
}

.color1 {
	color: #FFFFFF;
}

.color2 {
  color: #000000;
}

.color3 {
  color: #008364;
}

.color4 {
  color: rgba(0, 0, 0, 0.54);
}

.color5 {
  color: #0F8A6D;
}

.color6 {
  color: rgba(0, 0, 0, 0.38);
}

.color7 {
  color: rgba(0, 0, 0, 0.5);
}

.color8 {
  color: #262525;
}

.font-weight-4 {
	font-weight: 400;
}

.font-weight-5 {
	font-weight: 500;
}

.font-weight-6 {
	font-weight: 600;
}

.font-weight-b {
	font-weight: bold;
}

.font-family-Futura {
	font-family: 'Futura',sans-serif;
}

.font-family-Futura-Bold {
	font-family: 'Futura-Bold',sans-serif;
}

.font-family-Futura-Medium {
	font-family: 'Futura-Medium',sans-serif;
}

.font-family-Futura-Medium-Italic {
	font-family: 'Futura-Medium-Italic',sans-serif;
}

.textCenter {
  text-align: center;
}

.line-height-19 {
  line-height: 19px;
}

.line-height-21 {
  line-height: 21px;
}

a {
  text-decoration: none;
  display: block;
}

img {
  max-width: 100%;
}

.containerBox {
  width: 1440px;
  max-width: 100%;
}

@media only screen and (max-width: 1080px) {
  .containerBox {
    width: 375px;
  }
}

