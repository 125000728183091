#home-view {
	position: relative;
	.title-48 {
		line-height: 64px;
		font-size: 48px;
	}
	.banner {
		// height: 700px;
		background: #043d31; /* Old browsers */
		background: -moz-linear-gradient(top,  rgb(0, 0, 0) 0%, #043d31 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgb(0, 0, 0) 0%,#043d31 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgb(0, 0, 0) 0%,#043d31 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#043d31',GradientType=0 ); /* IE6-9 */
		// background: url(../../assets/images/banner.png);
		// background-size: 100% 100%;
		// background-repeat: no-repeat;
		position: relative;
		canvas {
			width: 100%!important;
		}
		.bannerContainer {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			.containerBox {
				position: relative;
				.header {
					height: 80px;
					.logo {
						margin-left: 150px;
					}
					.menuList {
						.menuItem {
							color: #FFFFFF;
							margin-left: 60px;
							&:first-child {
								margin-left: 0;
							}
						}
					}
					.invest {
						margin-right: 160px;
						width: 126px;
						height: 40px;
						background: linear-gradient(178deg, #087E60 0%, #0A5041 100%);
						border-radius: 20px;
						&:hover {
							cursor: pointer;
						}
					}
				}
				.container {
					position: absolute;
					top: 50%;
					transform: translatey(-50%);
					.title {
						line-height: 36px;
					}
					.desc {
						line-height: 48px;
						width: 1140px;
						max-width: 100%;
					}
				}
			}
		}
	}
	.approach {
		margin-top: 64px;
		.desc {
			width: 800px;
			max-width: 100%;
			line-height: 27px;
		}
	}
	.tokenList {
		margin-top: 68px;
		.tokenItem {
			width: 210px;
			margin-left: 30px;
			&:first-child {
				margin-left: 0;
			}
			.tokenDesc {
				line-height: 15px;
			}
		}
	}
	.whyBox {
		margin-top: 98px;
		width: 800px;
		max-width: 100%;
		.whyDesc {
			line-height: 27px;
			.whyDescItem {
				margin-top: 12px;
				&:first-child {
					margin-top: 0;
				}
			}
		}
		.tips {
			line-height: 15px;
		}
	}
	.echarts {
		margin-top: 70px;
		width: 800px;
    	max-width: 100%;
		.tab {
			width: 100%;
			border-bottom: 2px solid rgba(0, 0, 0, 0.1);
			.tabItem {
				margin-left: 82px;
				line-height: 32px;
				&:first-child {
					margin-left: 0;
				}
				&:hover {
					cursor: pointer;
				}
				.tabItemTitle {
					padding-bottom: 12px;
				}
				.tabBorder {
					width: 80px;
					height: 8px;
					background: #0F8A6D;
					border-radius: 6px 6px 0px 0px;
				}
			}
			.tabItemActive {
				color: #000000;
			}
		}
		.echartDesc {
			width: 100%;
		}
	}
	.treasuryBox {
		margin-top: 144px;
		.treasury {
			margin-left: 210px;
			.treasury-l {
				width: 640px;
				max-width: 100%;
				.title-48 {
					width: 370px;
					max-width: 100%;
					margin-top: 52px;
				}
			}
		}
	}
	.mission {
		margin-top: 90px;
		.missionList {
			margin-top: 38px;
			.missionItem {
				width: 306px;
				height: 349px;
				border-radius: 25px;
				border: 1px solid #D5D5D5;
				margin-left: 38px;
				&:first-child {
					margin-left: 0;
				}
				.missionDesc {
					margin-top: 26px;
					padding: 0 10px;
				}
			}
		}
	}
	.why {
		margin-top: 90px;
		padding-left: 210px;
		.why-r {
			margin-left: 44px;
			width: 640px;
			max-width: 100%;
		}
	}
	.participateBox {
		margin-top: 110px;
		.participate {
			.participateItem {
				width: 400px;
				height: 130px;
				border-radius: 15px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				margin-left: 40px;
				&:first-child {
					margin-left: 0;
				}
				&:hover {
					cursor: pointer;
				}
				.bond {
					width: 47px;
					height: 50px;
					background: url(../../assets/images/bonding.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					&:hover {
						background: url(../../assets/images/bonding2.png);
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
				}
				.stake {
					width: 40px;
					height: 41px;
					background: url(../../assets/images/staking.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					&:hover {
						background: url(../../assets/images/staking2.png);
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
	.roadmapBox {
		margin-top: 100px;
		.roadmap {
			height: 500px;
			// background: linear-gradient(180deg, #6EB8AA 0%, #FFFFFF 100%);
			background: url(../../assets/images/roadmapBg.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			padding-top: 104px;
			.timeTab {
				margin-top: 50px;
				margin-left: 48px;
				.tabItem {
					height: 36px;
					.timeheadeBox {
						width: 30px;
						.timeheadeActive {
							width: 30px;
							height: 6px;
							border-radius: 3px;
							background: #035642;
						}
					}
					.time {
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
			.timeheade {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: #D8D8D8;
			}
			.roadmapBoxContainer {
				margin-left: 96px;
				padding-bottom: 8px;
				flex: 1;
				overflow-x: scroll;
				display: -webkit-box;
            	-webkit-overflow-scrolling:touch;
				.roadmapBoxList {
					.roadmapItem {
						width: 420px;
						height: 340px;
						background: #FFFFFF;
						box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
						border-radius: 8px;
						margin-left: 10px;
						position: relative;
						&:first-child {
							margin-left: 0;
						}
						.roadmapIcon {
							position: absolute;
							top: 62px;
							left: 12px;
						}
						.title {
							height: 74px;
							border-bottom: 1px solid rgba(0, 0, 0, 0.06);;
							padding-left: 12px;
						}
						.roadmapContentList {
							line-height: 22px;
							padding: 0 20px 0 12px;
							.roadmapContent {
								margin-top: 8px;
								&:first-child {
									margin-top: 0;
								}
								.timeheade {
									margin-top: 6px;
								}
								.roadmapDesc {
									flex: 1;
								}
							}
						}
					}
				}
			}
			.roadmapBoxContainer::-webkit-scrollbar {
				height: 6px;
			}
			.roadmapBoxContainer::-webkit-scrollbar-thumb {
				/*滚动条里面小方块*/
				border-radius: 10px;
				box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				background: #1E5033;
			}
			.roadmapBoxContainer::-webkit-scrollbar-track {
				/*滚动条里面轨道*/
				box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
				border-radius: 10px;
				background   : #ededed;
			}
		}
	}
	.footerBox {
		height: 300px;
		background: #1E5033;
		.containerBox {
			padding: 0 130px;
			.footer-h {
				height: 104px;
				padding-left: 4px;
				padding-right: 30px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			}
			.footer {
				.footerItem {
					margin-left: 62px;
					&:first-child {
						margin-left: 0;
					}
					.title {
						line-height: 24px;
					}
					.desc {
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1080px) {
	#home-view {
		.drawer {
			position: fixed;
			left: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			background: #1C9732;
			border: 1px solid #979797;
			z-index: 999;
			.menuList {
				width: 100%;
				margin-top: 20px;
				.menuItem {
					margin: 20px 40px auto 40px;
					padding-left: 20px;
					height: 40px;
					line-height: 40px;
					border-radius: 22px;
					border: 1px solid #FFFFFF;
				}
			}
		}
		.title-48 {
			line-height: 32px;
			font-size: 24px;
		}
		.banner {
			// height: 590px;
			.bannerContainer {
				.containerBox {
					.header {
						height: 88px;
						.logo {
							margin-left: 20px;
						}
						.invest {
							margin-right: 14px;
						}
					}
					.container {
						.title {
							line-height: 15px;
							font-size: 12px;
						}
						.desc {
							margin-top: 6px;
							padding: 0 16px;
							font-size: 16px;
							line-height: 25px;
							width: 100%;
						}
					}
				}
			}
		}
		.approach {
			.desc {
				padding: 0 20px;
				margin-top: 22px;
				font-size: 14px;
				line-height: 19px;
			}
		}
		.tokenList {
			margin-top: 56px;
			padding: 0 20px;
			flex-wrap: wrap;
			.tokenItem {
				width: 50%;
				margin-left: 0;
				padding-top: 18px;
				padding-bottom: 20px;
				.tokenTitle {
					margin-top: 14px;
				}
				.tokenDesc {
					padding: 0 4px;
				}
			}
		}
		.whyBox {
			margin-top: 40px;
			.whyDesc {
				margin-top: 20px;
				padding: 0 20px;
				font-size: 14px;
				line-height: 19px;
				.whyDescItem {
					margin-top: 8px;
				}
			}
			.tips {
				padding: 0 20px;
			}
		}
		.echarts {
			margin-top: 20px;
			.tab {
				width: 100%;
				border-bottom: 2px solid rgba(0, 0, 0, 0.1);
				.tabItem {
					margin-left: 82px;
					line-height: 32px;
					&:first-child {
						margin-left: 0;
					}
					&:hover {
						cursor: pointer;
					}
					.tabItemTitle {
						padding-bottom: 12px;
					}
					.tabBorder {
						width: 80px;
						height: 8px;
						background: #0F8A6D;
						border-radius: 6px 6px 0px 0px;
					}
				}
				.tabItemActive {
					color: #000000;
				}
			}
			.echartDesc {
				margin-top: 20px;
				padding: 0 20px;
				font-size: 14px;
				line-height: 19px;
			}
		}
		.treasuryBox {
			margin-top: 74px;
			.treasury {
				margin-top: 0;
				margin-left: 0;
				flex-direction: column;
				padding: 0 20px;
				.treasury-l {
					width: 100%;
					z-index: 99;
					.title-48 {
						width: 100%;
						margin-top: 0;
						text-align: center;
					}
					.treasuryDesc,.treasuryDesc2 {
						margin-top: 8px;
						font-size: 14px;
						line-height: 19px;
					}
				}
				.treasury-r {
					margin-top: -58px;
				}
			}
		}
		.mission {
			margin-top: 40px;
			.missionList {
				margin-top: 10px;
				flex-direction: column;
				.missionItem {
					width: 100%;
					border: none;
					margin-left: 0;
					margin-top: 10px;
					.missionDesc {
						padding: 0 12px;
					}
				}
			}
		}
		.why {
			margin-top: 0;
			padding: 0 20px;
			flex-direction: column;
			.why-r {
				margin-left: 0;
				width: 100%;
				.title-48 {
					margin-top: -94px;
				}
				.desc {
					margin-top: 8px;
					font-size: 14px;
					line-height: 19px;
				}
				.desc2 {
					margin-top: 20px;
				}
			}
		}
		.participateBox {
			margin-top: 60px;
			.participate {
				margin-top: 20px;
				.participateItem {
					width: 130px;
					margin-left: 20px;
				}
			}
		}
		.roadmapBox {
			margin-top: 60px;
			.roadmap {
				height: auto;
				background: none;
				padding-top: 0;
				margin-top: 16px;
				.containerBox {
					display: flex;
					flex-direction: column;
				}
				.timeTab {
					margin-top: 0;
					margin-left: 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					.tabItem {
						height: 30px;
						.time {
							color: #000000;
						}
					}
				}
				.roadmapBoxContainer-m {
					margin-top: 30px;
					position: relative;
					.roadMapBg {
						width: 100%;
						height: 118px;
						position: absolute;
						bottom: -60px;
						background: url(../../assets/images/mobile/roadmapBg.png);
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					.roadmapBoxList {
						padding: 0 10px;
						position: relative;
						.next,.pre {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							z-index: 99;
						}
						.next {
							right: 0;
						}
						.pre {
							left: 0;
						}
						.roadmapItem {
							width: 100%;
							height: 340px;
							background: #FFFFFF;
							box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
							border-radius: 8px;
							position: relative;
							.roadmapIcon {
								position: absolute;
								top: 62px;
								left: 12px;
							}
							.title {
								height: 74px;
								border-bottom: 1px solid rgba(0, 0, 0, 0.06);;
								padding-left: 12px;
							}
							.roadmapContentList {
								line-height: 22px;
								padding: 0 20px 0 12px;
								.roadmapContent {
									margin-top: 8px;
									&:first-child {
										margin-top: 0;
									}
									.timeheade {
										margin-top: 6px;
									}
									.roadmapDesc {
										flex: 1;
									}
								}
							}
						}
					}
				}
			}
		}
		.footerBox {
			margin-top: 60px;
			.containerBox {
				padding: 0;
				.footer-h {
					padding-left: 16px;
					padding-right: 16px;
					margin: 0 10px;
				}
				.footer {
					.footerItem {
						margin-left: 20px;
						&:first-child {
							margin-left: 20px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 321px) {
	#home-view {
		.footerBox {
			.footer {
				.footerItem {
					font-size: 12px;
					margin-left: 12px;
					&:first-child {
						margin-left: 12px;
					}
				}
			}
		}
	}
}