@font-face
{
  font-family: 'Futura',-apple-system,BlinkMacSystemFont;
  src: url('Futura.ttf') format('truetype');
}

@font-face
{
  font-family: 'Futura-Bold',-apple-system,BlinkMacSystemFont;
  src: url('futura-medium-bt.ttf') format('truetype');
}

@font-face
{
  font-family: 'Futura-Medium',-apple-system,BlinkMacSystemFont;
  src: url('Futura-Medium.ttf') format('truetype');
}

@font-face
{
  font-family: 'Futura-Medium-Italic',-apple-system,BlinkMacSystemFont;
  src: url('Futura-Medium-Italic.ttf') format('truetype');
}